<template>
  <LinkNote :id="$route.query.id" />
</template>

<script>
import LinkNote from './components/LinkNote.vue'

export default {
  name: 'App',
  components: {
    LinkNote
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
}
</style>
