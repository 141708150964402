import { createApp } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import Amplify from "aws-amplify";
import AmplifyVue from '@aws-amplify/ui-vue';
import awsExports from "./aws-exports";
import App from './App.vue'

Amplify.configure(awsExports);

const routes = [
    { path: '/', component: App }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

createApp(App)
    .use(router)
    .use(AmplifyVue)
    .mount('#app')
