<template>
  <template v-if="loading">Loading note {{ id }}</template>
  <template v-else-if="note"><div v-html="note"></div></template>
  <template v-else-if="error"
    ><div>{{ error }}</div></template
  >
</template>

<script>
import { API } from "aws-amplify";

export default {
  name: "LinkNote",
  props: ["id"],
  data() {
    return {
      loading: true,
      note: null,
    };
  },
  watch: {
    // eslint-disable-next-line
    async id(newId, _) {
      if (newId) {
        try {
          this.loading = true;
          const result = await API.get("rendernote", `/${newId}`);
          this.note = result.output;
        } catch (e) {
          this.error = e;
        }
        finally {
            this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
@import '../assets/styles.css';
</style>